<template>
  <div class="followUp">
    <el-form inline class="form-content">
      <el-form-item label="随访名称">
        <el-input placeholder="输入关键字" v-model="contentName" class="wd200"></el-input>
      </el-form-item>
      <el-form-item label="随访分类">
        <el-select placeholder="请选择分类" v-model="followUpType" clearable style="width:200px;">
          <el-option
            v-for="(item,index) in [{name:'问卷',value:1},{name:'宣教',value:2}]"
            :key="index"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发送时间">
        <el-date-picker
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd "
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd "
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="search-list">
      <el-button type="primary" @click="addFollow">添加</el-button>
      <el-button type="danger" @click="del" :disabled="!dataListSelections.length">删除</el-button>
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="followUpType" label="随访类型">
          <template slot-scope="scope">{{scope.row.followUpType==1?'问卷':"宣教"}}</template>
        </el-table-column>
        <!-- <el-table-column prop='contentTypeName' label='随访分类' > </el-table-column> -->
        <el-table-column prop="followUpName" label="随访名称"></el-table-column>
        <el-table-column prop="followUpTime" label="发送日期"></el-table-column>
        <el-table-column prop="contentName" label="随访内容">
          <template slot-scope="scope">
            <!-- contentNames -->
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.contentNames"
              placement="top"
            >
              <div class="txt-cut">{{scope.row.contentNames}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- <el-table-column prop='completionTime' label='结束日期' > </el-table-column> -->
        <el-table-column prop="number" label="发送人数"></el-table-column>
        <el-table-column prop="completedNumber" label="完成人数"></el-table-column>
        <el-table-column label="操作" fixed="right" align="right" header-align="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goToInfo(scope.row)">详情</el-button>
            <!-- <el-button type="text" size="small" @click='goToEdit(scope.row)'>修改</el-button>
            <el-button type="text" size="small" @click='del(scope.row)' style='color:#f56c6c'>删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import Qs from "qs";
export default {
  name: "followUp",
  data() {
    return {
      activeName: "first",
      dateTime: ["", ""],
      contentName: "",
      followUpType: "",
      dataList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      endTime: [],
      dataList2: [],
      dateTime2: ["", ""],
      contentName2: "",
      followUpType2: "",
      dataListLoading2: true, //列表loading
      dataListSelections2: [], //选中的列
      pageNo2: 1,
      pageSize2: 10,
      totalPage2: 0,
      endTime2: [],
    };
  },
  async mounted() {
    // await this.getDict()
    await this.getList();
    // await this.getList2()
  },
  methods: {
    addFollow() {
      this.$router.push("/followUpList/add");
    },
    del() {
      const ids = [];
      this.dataListSelections.forEach((v) => {
        ids.push(v.id);
      });
      const id = ids.join(`,`);
      this.$confirm("确定进行[删除随访]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/followUp/delete?ids=${id}`),
            method: "post",
          });
          console.log(res);
          if (res.status) {
            this.$message.success("删除随访成功");
            this.getList();
          }
        })
        .catch(() => {});
    },
    async getDict() {
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/dict/getSysDicts?type=" + "宣教分类"),
        method: "get",
        params: {
          teamsId: JSON.parse(localStorage.currentTeam).id,
          pageSize: 999,
          pageNo: 1,
        },
      });
      console.log(res, "res");
      this.xjList = res.data;
    },
    async getList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/followUp/getFollowUpFeedback"),
        method: "post",
        data: {
          stringParam6: this.contentName,
          // hospitalId:user.deptPid,
          // deptId::user.deptId,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
          stringParam1: this.followUpType,
          stringParam8: this.dateTime[0],
          stringParam9: this.dateTime[1],
          stringParam10: this.endTime[0],
          stringParam11: this.endTime[1],
          // noticeType:this.noticeType,
         stringParam4: teamsId,
         stringParam5: enertyId,
          // completionStatus:1
        },
      });
      if (res.status) {
        res.data.forEach((v) => {
          if (v.followUpType == 2) {
            // this.xjList.forEach(v2=>{
            //   if(v.contentType==v2.value){
            //     v.contentTypeName = v2.name
            //   }
            // })
            v.contentTypeName = v.contentTypeFiveName
              ? v.contentTypeFiveName
              : v.contentTypeFourName
                ? v.contentTypeFourName
                : v.contentTypeThreeName
                  ? v.contentTypeThreeName
                  : v.contentTypeSecName
                    ? v.contentTypeSecName
                    : v.contentTypeName;
          }
        });
        this.dataList = res.data;
        this.totalPage = res.totalCount;
        this.total = res.totalCount;
        this.dataListLoading = false;
      }
    },
    async getList2() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/followUp/getFollowUpFeedback"),
        method: "post",
        data: {
          stringParam6: this.contentName2,
          // hospitalId:user.deptPid,
          // deptId::user.deptId,
        
		  intParam1: this.pageSize2,
		  intParam2: this.pageNo2,
          stringParam1: this.followUpType2,
          stringParam8: this.dateTime2[0],
          stringParam9: this.dateTime2[1],
          stringParam10: this.endTime2[0],
          stringParam11: this.endTime2[1],
          // noticeType:this.noticeType,2
         stringParam4: teamsId,
         stringParam5: enertyId,
          intParam3: 2,
        },
      });
      if (res.status) {
        res.data.forEach((v) => {
          if (v.followUpType == 2) {
            // this.xjList.forEach(v2=>{
            //   if(v.contentType==v2.value){
            //     v.contentTypeName = v2.name
            //   }
            // })
            v.contentTypeName = v.contentTypeFiveName
              ? v.contentTypeFiveName
              : v.contentTypeFourName
                ? v.contentTypeFourName
                : v.contentTypeThreeName
                  ? v.contentTypeThreeName
                  : v.contentTypeSecName
                    ? v.contentTypeSecName
                    : v.contentTypeName;
          }
        });
        this.dataList2 = res.data;
        this.totalPage2 = res.totalCount;
        this.total2 = res.totalCount;
        this.dataListLoading2 = false;
      }
    },
    reset() {
      this.contentName = "";
      this.followUpType = "";
      this.dateTime = ["", ""];
      this.endTime = ["", ""];
    },
    reset2() {
      this.contentName2 = "";
      this.followUpType2 = "";
      this.dateTime2 = ["", ""];
      this.endTime2 = ["", ""];
    },
    goToInfo(row) {
			let ids = ''
			if(row.contentIds!=null){
				ids = row.contentIds
			}else{
					ids = row.contentId
			}
      this.$router.push({
        path: "/followUpList/done",
        query: {
          ids: ids,
          type: row.followUpType,
          name: row.followUpName,
        },
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getList2();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getList2();
    },
    // 多选
    selectionChangeHandle2(val) {
      this.dataListSelections2 = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.followUp {
  .search-list {
    // display: flex;
    // align-items: center;
    .label {
      width: 5%;
    }
  }
}
</style>